import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage: React.FC = () => {
  return (
    <Layout bgColor="black">
      <SEO title="Legal Notice & Imprint" />
      <div className="w-max-screen flex max-w-screen-lg flex-col space-y-10 overflow-hidden px-4 lg:mx-auto">
        <h1
          id="imprint"
          className="container m-0 mt-12 pl-0 font-gravity-compressed text-[60px] uppercase lg:text-[80px]"
        >
          Imprint
        </h1>
        <div className="space-y-3">
          <p className="font-gravity-wide text-xl">
            Information according to § 5 TMG
          </p>
          <address className="not-italic">
            OREMOB UG (Gesellschaft mit beschränkter Haftung)
            <br />
            Stubenrauchstr. 47
            <br />
            12161 Berlin, Germany
          </address>
        </div>

        <div className="space-y-3">
          <ul className="list-none">
            <li>Managing Directors: Ole Paland, Roland-James Weber</li>
            <li>E-Mail: mob@oremob.io</li>
          </ul>
        </div>

        <div>
          In accordance with Art. 14 (1) of Regulation (EU) No. 524/2013 on
          Online Dispute Resolution (ODR Regulation), OREMOB is legally obliged
          to refer the Buyer to the European Online Dispute Resolution platform
          (ODR platform) of the European Commission. The User can reach this at{" "}
          <a
            href="http://ec.eoropa.eu/consumers/odr/"
            target="_blank"
            rel="noreferrer"
          >
            http://ec.eoropa.eu/consumers/odr/
          </a>
          . OREMOB does not participate in dispute resolution proceedings before
          a consumer arbitration board and is not obliged to do so.
        </div>

        <div className="space-y-3">
          <ul className="list-none">
            <li>
              Website made with love by:{" "}
              <a href="https://mobydigg.de" target="_blank" rel="noreferrer">
                Moby Digg
              </a>
            </li>
          </ul>
        </div>

        <h2
          id="toc"
          className="m-0 pl-0 font-gravity-compressed text-[60px] uppercase lg:text-[80px]"
        >
          Terms and Conditions
        </h2>

        <section className="flex flex-col space-y-4">
          <p>
            These terms and conditions (&quot;<b>T&amp;C</b>&quot;) of OREMOB
            UG, Stubenrauchstr. 47, 12161 Berlin, Germany (&quot;<b>OREMOB</b>
            &quot;) apply to the use of the website oremob.io, related websites
            and social media channels (&quot;<b>ORE Platform</b>&quot;), in
            particular to the purchase and transfer of NFTs (as defined below)
            and to every service accessible on the ORE Platform and provided by
            OREMOB (&quot;<b>Services</b>&quot;) to the extent that the ORE
            Platform and the Services refer to this T&amp;C (the underlying
            agreement incorporating the T&amp;C is herein after referred to as
            the &quot;<b>Platform Agreement</b>&quot;).
          </p>
          <ol className="space-y-6">
            <li>
              <h3 className="text-xl">1 DEFINED TERMS</h3>
              <p>
                In this T&amp;C, except where set forth otherwise, the following
                terms shall have the following meanings:
                <p>
                  <br />
                </p>
              </p>
              <ol className="list-inside list-disc pl-6">
                <li>
                  &quot;
                  <b>ADA</b>&quot; means the native cryptocurrency of the
                  blockchain Cardano.
                </li>
                <li>
                  &quot;
                  <b>Blockchain</b>&quot; means the blockchain or comparable
                  technology named on the Website for the NFT (as defined below)
                  offered and if nothing is stated otherwise on the ORE
                  Platform, the blockchain Cardano.
                </li>
                <li>
                  &quot;
                  <b>Buyer</b>&quot; means any natural person who submits an
                  offer to purchase an NFT (as defined below).
                </li>
                <li>
                  &quot;
                  <b>Consumer</b>&quot; means means any natural person who
                  enters into a legal transaction for purposes that are
                  predominantly neither commercial nor self-employed, in the
                  sense of Section 13 of the German Civil Code (BGB).
                </li>
                <li>
                  &quot;
                  <b>Wallet</b>&quot; means a means a software or other system
                  that enables the Buyer to manage its cryptographic keys (as
                  defined below) and the Tokens assigned to them.
                </li>
                <li>
                  &quot;
                  <b>Artwork</b>&quot; means the artwork associated with the NFT
                  (as defined below), for example a graphic or an animation, as
                  described on the ORE Platform.
                </li>
                <li>
                  &quot;
                  <b>NFT</b>&quot; means the contractual and transferable Token
                  (as defined below) purchasable on the ORE Platform and any
                  rights, goods or assets associated with the Token as indicated
                  on the ORE Platform, in particular the contractual rights to
                  and in the Artwork.
                </li>
                <li>
                  &quot;
                  <b>Owner of the NFT</b>&quot; is anyone who can dispose of the
                  NFT by means of a corresponding cryptographic key.
                </li>
                <li>
                  &quot;
                  <b>Token</b>&quot; means a token on the Blockchain.
                </li>
                <li>
                  &quot;
                  <b>Purchase Agreement</b>&quot; means the underlying agreement
                  on the purchase of an NFT between OREMOB and the Buyer
                  incorporating this T&amp;C.
                </li>
                <li>
                  &quot;
                  <b>Purchase Price</b>&quot; means the price to be paid by the
                  Buyer to OREMOB for the NFT.
                </li>
                <li>
                  &quot;
                  <b>User</b>&quot; means the visitor of the ORE Platform, user
                  of the Services and, if applicable, Buyer of an NFT.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">2 PURCHASE OF THE NFT</h3>
              <ol className="list-inside list-disc">
                <li>
                  OREMOB offers NFTs for sale on the ORE Platform. OREMOB&#39;s
                  offer on the ORE Platform does not yet constitute a binding
                  offer to conclude a Purchase Agreement, but merely an
                  invitation to submit a binding offer by the Buyer.
                </li>
                <li>
                  The Buyer makes such a binding offer to purchase an NFT via
                  the ORE Platform by truthfully providing all data requested on
                  the ORE Platform, in particular the address of his Wallet, and
                  clicking the &quot;Mint&quot; button. &zwnj;
                </li>
                <li>
                  Upon submission of the offer the Buyer can reserve an NFT for
                  a limited amount of time (ten (10) to 60 minutes) as indicated
                  on the ORE Platform in order to complete the purchase by
                  payment of the Purchase Price. If the purchase process is not
                  completed within this period, OREMOB will cancel the purchase
                  process. The Buyer must initiate the purchase process again.
                </li>
                <li>
                  External costs (so-called &quot;
                  <b>Transaction Fees</b>&quot;) can incur for transactions via
                  the Blockchain. These are external costs associated with the
                  allocation of the NFT and its modification on the Blockchain,
                  which cannot be influenced by OREMOB. The amount of the
                  Transaction Fees can only be determined immediately before the
                  purchase of a NFT and will be communicated in the purchase
                  process; it may vary from purchase to purchase. Transaction
                  Fees are the responsibility of the Buyer and are payable in
                  addition to the Purchase Price. The Transaction Fees
                  communicated in the ordering process are valid for ten (10)
                  minutes; if the purchase process is not completed within this
                  period, OREMOB will cancel the purchase process. The Buyer
                  must then initiate the purchase process again.
                </li>
                <li>
                  The Buyer is obliged to pay the agreed Purchase Price as
                  indicated on the ORE Platform. Unless otherwise stated,
                  payment of the Purchase Price shall be made in ADA in
                  accordance with the offer description on the ORE Platform. The
                  Purchase Price shall become due upon submission of the offer
                  by the Buyer in accordance with section 2.2 above. The Buyer
                  shall transfer the Purchase Price to the wallet address of
                  OREMOB as indicated on the ORE Platform. Unless otherwise
                  stated, the Purchase Price shall include the respective
                  legally applicable value added tax (VAT) on the Purchase
                  Price.&zwnj;
                </li>
                <li>
                  OREMOB accepts the Buyer&#39;s offer by transferring the NFT
                  to the Buyer&#39;s wallet address, with which OREMOB at the
                  same time confirms the purchase. The acceptance by OREMOB is
                  conditional upon payment of the Purchase Price in accordance
                  with section 2.5.
                </li>
                <li>
                  If OREMOB rejects the Buyer&#39;s offer or if the Buyer
                  withdraws his offer prior to the OREMOB&#39;s acceptance,
                  OREMOB shall return to the Buyer any Purchase Price already
                  paid to the Buyer within fourteen (14) days.
                </li>
                <li>
                  Unless otherwise stated on the ORE Platform, OREMOB provides
                  the Artwork as a .GIF and in addition as a high resolution
                  .MP4 file for download.
                </li>
                <li>
                  OREMOB&#39;s offer is only directed at persons who have
                  reached the age of eighteen (18).
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">3 WALLET</h3>
              <ol className="list-inside list-disc">
                <li>
                  The purchase, disposal and otherwise possible use of the NFTs
                  via the ORE Platform requires a wallet. OREMOB does not offer
                  or operate any wallets. The Buyer must ensure that her wallet
                  has the necessary compatibility with the Blockchain used by
                  the ORE Platform. Details regarding supported wallets are
                  provided on the ORE Platform oremob.io.
                </li>
                <li>
                  The management of the NFT after transfer to the Wallet address
                  of the Buyer is not part of the performance of OREMOB and is
                  solely the responsibility of the Buyer.
                </li>
                <li>
                  OREMOB transfers the purchased NFT to the Buyer&#39;s Wallet
                  address. The Wallet contains a cryptographic key with which
                  the Buyer can dispose of his NFTs. OREMOB has no access to the
                  cryptographic keys or Tokens of the Buyer.
                </li>
                <li>
                  The Buyer shall ensure that he treats the Wallet, associated
                  passwords and access data, the cryptographic key and any
                  recovery identifiers (seeds) (together and individually the
                  &quot;
                  <b>Wallet Access Data</b>&quot;), confidentially and that the
                  Wallet Access Data, insofar as he has access to it, is secured
                  against access by third parties by means of appropriate
                  technical and organisational measures. The Buyer is aware that
                  any person who has access to Wallet Access Data may misuse the
                  wallet under his name. The Buyer warrants that third parties
                  are not enabled to use the Wallet Access Data. In particular,
                  to protect the Wallet Access Data, the Buyer shall not store
                  the Wallet Access Data in an unsecured manner and shall
                  prevent spying when entering the Wallet Access Data. If the
                  Buyer discovers that another person has knowledge of the
                  Wallet Access Data or if the Buyer has a corresponding
                  suspicion, the Buyer must immediately change the Wallet Access
                  Data concerned, insofar as this is possible.
                </li>
                <li>
                  The Buyer is aware that he is solely responsible for the
                  control of his Wallet and that the loss of control over the
                  Wallet may result in the rights to a NFT no longer being able
                  to be proven and that NFTs can no longer be disposed of.
                </li>
                <li>
                  The Buyer is aware that the Wallet is not operated by OREMOB,
                  but by a third-party wallet provider. OREMOB has no influence
                  on wallet providers or the wallet operation. OREMOB only
                  permits the Buyer to use the Wallet on the ORE Platform. The
                  Buyer understands that OREMOB does not have access to the
                  Wallet Access Data and cannot recover the Wallet Access Data.
                  In particular, OREMOB cannot restore access to NFTs if the
                  Buyer loses the Wallet Access Data or third parties have
                  disposed of NFTs.
                </li>
                <li>
                  The Buyer understands that this T&amp;C are an addition to any
                  terms of use of the respective wallet provider. In the event
                  of any conflict, this T&amp;C shall prevail over the terms of
                  use of the wallet provider with respect to the relationship
                  between OREMOB and the Buyer. However, the wallet provider
                  shall be entitled to enforce the rights arising from its terms
                  of use directly against the Buyers.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">4 LICENSE TO THE ARTWORK &zwnj;</h3>
              <ol className="list-inside list-disc">
                <li>
                  By transferring the NFT to the Buyer, OREMOB irrevocably
                  grants to the Buyer, limited in time to the period during
                  which he is Owner of the NFT and subject to the condition
                  precedent of payment of the Purchase Price pursuant to section
                  2.5, the worldwide, exclusive, not-sublicensable and
                  not-transferable right to use, copy, display, print and make
                  publicly available to the Artwork in connection with the NFT
                  solely:&zwnj;
                  <ol className="list-inside list-disc pl-6">
                    <li>
                      for the Owner of the NFT&#39;s non-commercial and
                      commercial use;
                    </li>
                    <li>
                      to offer the Artwork together with the NFT for sale to a
                      third party (&quot;
                      <b>Subsequent Sale</b>
                      &quot;), provided that (a) the resale is a Subsequent Sale
                      in accordance with section 6 and (b) if and to the extent
                      such Subsequent Sale has been previously permitted by
                      OREMOB on the ORE Platform with regard to the respective
                      NFT and the respective third party marketplace.
                    </li>
                    <li>
                      to display the Artwork on third-party websites, provided
                      that they make the display of the Artwork conditional on a
                      positive cryptographic verification of the Owner of the
                      NFT.
                    </li>
                  </ol>
                  <br />
                  <p>
                    All of the above grants of rights are fully satisfied. The
                    Buyer accepts all the above grants of rights.
                  </p>
                </li>
                <li>
                  The license under section 4.1 is not transferrable. If the
                  Buyer transfers the NFT to a third party, the rights of use to
                  the Artwork granted in accordance with section 4.1 shall
                  expire. The License under section 5.1 is solely transferable
                  in case of section 7 (License Assumption).
                </li>
                <li>
                  The Buyer acknowledges that all legal rights, title and
                  interest in and to the Artwork and all intellectual property
                  rights therein are owned by the originator of the Artwork. The
                  Buyer&#39;s rights to the Artwork are limited to the rights of
                  use described in this section 4. The originator and OREMOB
                  reserve all rights in the Artwork not expressly granted to the
                  Buyer by this Platform Agreement.
                </li>
                <li>
                  A further right of use or any other right in an Artwork shall
                  only be granted to the Owner of the NFT if this is expressly
                  stated in the respective NFT description of OREMOB on the ORE
                  Platform.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">5 RESTRICTIONS OF USE &zwnj;</h3>
              <ol className="list-inside list-disc">
                <li>
                  The License pursuant to sections 4 is subject to the condition
                  subsequent that the Owner of the NFT complies with the
                  provisions of section 15 (Anti-Money Laundering).
                </li>
                <li>
                  The License pursuant to section 4 is subject to the condition
                  precedent that the Owner of the NFT has lawfully acquired the
                  NFT. This requirement shall be met in the moment the Buyer
                  receives the NFT, unless this reception of the NFT is not
                  lawful, in particular, but without limitation, in cases of (a)
                  theft or other unauthorised transfer of the Token from the
                  wallet address of OREMOB or a Reseller (as defined below) to
                  the wallet address of a third party and (b) an unintentional
                  transfer of the Token to the wallet address of a third party
                  by OREMOB or a Reseller (as defined below) (e.g., by mistyping
                  when entering the wallet address). In such cases, OREMOB
                  reserves the right to transfer the Token to the proper wallet
                  address or, if applicable, to destroy (burn) the Token (Buyer
                  is obliged to cooperate) and reissue the Token to the rightful
                  holder of the rights to the Artwork and any other rights.
                </li>
                <li>
                  Except for a Subsequent Sale of the NFT in accordance with
                  section 6, the Owner of the NFT shall not be entitled,
                  directly or indirectly, without the prior written consent of
                  OREMOB:
                  <ol className="list-inside list-disc pl-6">
                    <li>
                      to alter the Artwork or create derivative works from it,
                      including but not limited to shapes, designs, drawings,
                      attributes, features or colours, except for non-public and
                      non-commercial, purely private purposes;
                    </li>
                    <li>
                      to use the Artwork in films, videos or other forms of
                      media, except to the extent expressly permitted under this
                      Agreement, except for non-public and non-commercial,
                      purely private purposes;
                    </li>
                    <li>
                      to exploit the Artwork for commercial purposes, in
                      particular to advertise, market or sell a product or
                      service of oneself or a third party. For avoidance of
                      doubt: Use for purely private purposes is permitted;
                    </li>
                    <li>
                      to take measures aimed at circumventing technical
                      protection measures of the NFT. The owner of the NFT shall
                      refrain from any form of unauthorised use, in particular
                      attempts to overcome or circumvent the security mechanisms
                      or otherwise disable them, and shall take all necessary
                      and reasonable steps to prevent or limit any damage caused
                      by the use of the NFT;
                    </li>
                    <li>
                      to use the NFT for purposes and/or in connection with
                      materials (e.g. texts, images, films, video clips) and/or
                      to disseminate information in connection,
                      <ol className="list-inside list-disc pl-12">
                        <li>which contain criminal content;</li>
                        <li>
                          constitute hatred, intolerance, violence,
                          discrimination or any other form of disregard for the
                          rights of third parties or otherwise violates the
                          rights of third parties, in particular offensive or
                          derogatory material relating to gender, race,
                          religion, skin colour, origin, age, physical or mental
                          disability, medical condition or sexual orientation;
                        </li>
                        <li>
                          infringe the rights of third parties (in particular
                          copyrights, publication rights, patents, trademarks,
                          service marks, trade names, trade secrets or other
                          intellectual property rights);
                        </li>
                        <li>are associated with gambling activities;</li>
                        <li>violate a person&#39;s privacy;</li>
                        <li>are otherwise unlawful or unreasonable.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">
                6 SUBSEQUENT SALE AND REVENUE SHARE &zwnj; &zwnj;
              </h3>
              <ol className="list-inside list-disc">
                <li>
                  OREMOB grants to every purchaser of the NFT as well as to
                  every subsequent purchaser in the chain of sale of the NFT
                  (&quot;
                  <b>Subsequent Buyer</b>
                  &quot;) the License pursuant to section 4 limited in time to
                  the period during which this Subsequent Buyer is Owner of the
                  NFT and subject to the condition precedent of payment of the
                  Revenue Share pursuant to section 6.2. The Subsequent Buyer
                  accepts the granting of all rights by claiming and exercising
                  the rights of use and any other rights represented in the NFT
                  (e.g., by using the Artwork). OREMOB waives receipt of the
                  acceptance. Regardless of this acceptance, OREMOB permits the
                  use in terms of this section 6.1. &zwnj;
                </li>
                <li>
                  In case of a Subsequent Sale, the Subsequent Buyer pays 6 % of
                  the respective purchase price, including applicable VAT, to
                  OREMOB (&quot;
                  <b>Revenue Share</b>&quot;). The Revenue Share is always
                  payable by the Subsequent Buyer. The remaining 94 % of the
                  purchase price is paid by the Subsequent Buyer to the previous
                  owner of the NFT as seller of the NFT (&quot;
                  <b>Reseller</b>&quot;), including applicable VAT. The
                  third-party marketplaces available for a Subsequent Sale
                  typically execute both payments automatically as soon as the
                  Subsequent Buyer transfers the purchase price. &zwnj;
                </li>
                <li>
                  In the event of a Subsequent Sale, the Subsequent Buyer may
                  incur additional costs for the transaction, depending on the
                  third-party marketplaces chosen by the Reseller.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">7 LICENSE ASSUMPTION &zwnj;</h3>
              <ol className="list-inside list-disc">
                <li>
                  Deviating from section 4.2 sentence 1 and 2, in the event of a
                  Subsequent Sale in accordance with section 4.1, every
                  Subsequent Buyer can fully assume all rights of use to the
                  Artwork pursuant to section 5 in place of the Buyer (&quot;
                  <b>License Assumption</b>&quot;).
                </li>
                <li>
                  The Buyer&#39;s offer to a third party to sell the NFT shall
                  be interpreted as the Buyer offering the third party as the
                  new Owner of the NFT to take over the License according to
                  section 3.7.
                </li>
                <li>
                  OREMOB gives the consent required for the License Assumption
                  under the condition subsequent that (a) the Token has been
                  successfully transferred to the wallet address of the
                  Subsequent Buyer, (b) that the Subsequent Buyer is rightful
                  Owner of the NFT, (c) subject to the condition precedent of
                  payment of the Revenue Share pursuant to section 6.2 and (d)
                  that the Subsequent Buyer complies with the provisions of
                  section 15 (Anti-Money Laundering).
                </li>
                <li>Section 5 (Restrictions of Use) applies accordingly.</li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">8 WARRANTY</h3>
              <ol className="list-inside list-disc">
                <li>
                  OREMOB warrants that the Token at the time of transfer to the
                  Buyer (a) corresponds to the standard market quality of the
                  particular token type as indicated on the ORE Platform, (b) to
                  the condition described on the ORE Platform and is thus in
                  accordance with the Purchase Agreement and (c) that no
                  third-party rights oppose the use by the Buyer to the
                  contractually agreed extent. OREMOB cannot assume any warranty
                  beyond the forementioned, in particular for the tradability of
                  the NFT on third-party platforms and marketplaces and the
                  compatibility with other networks, infrastructures,
                  blockchains and wallets of third parties other than those
                  named on the ORE Platform. The tradability of the NFT on
                  third-party platforms and marketplaces and the compatibility
                  with other networks, infrastructures, blockchains and wallets
                  depend on an approval decision, conditions or form
                  requirements of a third party and may not be practically
                  feasible due to deviating token standards for technical or
                  other reasons.
                </li>
                <li>
                  OREMOB warrants that the Artwork made available as a download
                  – unless otherwise described on the ORE Platform – corresponds
                  to the description on the ORE Platform. The availability,
                  resolution and quality of the Artwork depends on the device
                  used by the Buyer and other factors, which are beyond the
                  control of OREMOB and for which no warranty is assumed.
                </li>
                <li>
                  With regard to the Token, supplementary performance (
                  <i>Nacherfüllung</i>) is only possible by destroying the
                  defective Token (burning) and issuing a new Token to the
                  Wallet address of the Buyer. The Buyer is obliged to cooperate
                  in the burning of the defective Token in accordance with
                  OREMOB&#39;s instructions (e.g. by transferring the Token to a
                  wallet address specified by OREMOB) and to accept a new Token
                  if the contractual scope of functions is maintained and the
                  acceptance does not lead to significant disadvantages for the
                  Buyer.
                </li>
                <li>
                  In all other respects, the warranty shall be determined in
                  accordance with the statutory provisions.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">
                9 INVALIDATION OF TOKENS IN CASE OF MISUSE
              </h3>
              <ol className="list-inside list-disc">
                <li>
                  If there is a reasonable suspicion that the Buyer has breached
                  his obligations under this Platform Agreement and the Buyer
                  does not remedy such breach within fourteen (14) days after a
                  time limit has been set, OREMOB reserves the right to transfer
                  the Token to the proper wallet address or, if necessary, to
                  destroy (burn) the Token (Buyer is obliged to cooperate) and
                  reissue (mint) it to the rightful holder of the rights of use
                  to the Artwork. A notice period is not required in the case of
                  irremediable violations or in the case of serious violations,
                  such as those based on intent.
                </li>
                <li>
                  The Owner of the NFT is obliged to cooperate in the
                  destruction of the Token as specified by OREMOB (e.g. by
                  transferring the Token to the wallet address specified by
                  OREMOB).
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">9 RIGHT OF WITHDRAWAL</h3>
              <p>
                Consumers have a right of withdrawal in accordance with the
                following provisions:
              </p>
              <br />
              <div className="border-[1px] border-white p-6">
                <div className="text-center text-lg font-bold">
                  Model instructions on withdrawal
                </div>
                <div className="text-lg font-bold">Right of withdrawal</div>
                <p>
                  You have the right to withdraw from this contract within 14
                  days without giving any reason. The withdrawal period will
                  expire after 14 days from the day of the conclusion of the
                  contract.
                </p>
                <p>To exercise the right of withdrawal, you must inform us</p>
                <p>
                  <br />
                </p>
                <p>OREMOB UG</p>
                <p>Stubenrauchstr. 47</p>
                <p>12161 Berlin</p>
                <p>
                  <a
                    href="mailto:mob@oremob.io"
                    className="s1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    E-mail:{" "}
                  </a>
                  <a
                    href="mailto:mob@oremob.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    mob@oremob.io
                  </a>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  of your decision to withdraw from this contract by an
                  unequivocal statement (e.g. a letter sent by post, fax or
                  e-mail). You may use the attached model withdrawal form, but
                  it is not obligatory.
                </p>
                <p>
                  To meet the withdrawal deadline, it is sufficient for you to
                  send your communication concerning your exercise of the right
                  of withdrawal before the withdrawal period has expired.
                </p>
                <p>
                  <br />
                </p>
                <div className="text-lg font-bold">Effects of withdrawal</div>
                <p>
                  If you withdraw from this contract, we shall reimburse to you
                  all payments received from you, including the costs of
                  delivery (with the exception of the supplementary costs
                  resulting from your choice of a type of delivery other than
                  the least expensive type of standard delivery offered by us),
                  without undue delay and in any event not later than 14 days
                  from the day on which we are informed about your decision to
                  withdraw from this contract. We will carry out such
                  reimbursement using the same means of payment as you used for
                  the initial transaction, unless you have expressly agreed
                  otherwise; in any event, you will not incur any fees as a
                  result of such reimbursement.
                </p>
              </div>
              <div className="mt-6 border-[1px] border-white p-6">
                <div className="text-center text-lg font-bold">
                  Model withdrawal form
                </div>
                <p>
                  (If you wish to revoke the contract, please complete and
                  return this form).
                </p>
                <p>
                  <br />
                </p>
                <p>To</p>
                <p>
                  <br />
                </p>
                <p>OREMOB UG</p>
                <p>Stubenrauchstr. 47</p>
                <p>12161 Berlin</p>
                <p>
                  E-mail: <a href="mailto:mob@oremob.io">mob@oremob.io</a>
                </p>
                <p>
                  <br />
                </p>
                <p>
                  I/We (*) hereby give notice that I/We (*) withdraw from my/our
                  (*) contract of sale of the following goods (*)
                </p>
                <p>Ordered on (*)/received on (*), Name of consumer(s),</p>
                <p>Address of consumer(s),</p>
                <p>
                  Signature of consumer(s) (only if this form is notified on
                  paper),
                </p>
                <p>Date</p>
                <p>
                  <br />
                </p>
                <p>(*) Delete as appropriate.</p>
              </div>
            </li>
            <li>
              <h3 className="text-xl">
                11 EXCEPTIONS TO THE RIGHT OF WITHDRAWAL
              </h3>
              <p>
                Unless the parties have agreed otherwise, the right of
                withdrawal does not apply to the following contracts:
              </p>
              <ol className="list-inside list-disc">
                <li>
                  contracts for the supply of sound or video recordings or
                  computer software in a sealed package if the seal has been
                  removed after delivery (§ 312g Abs. 2 No. 6 BGB);
                </li>
                <li>
                  contracts for the supply of goods or for the provision of
                  services, including financial services, the price of which
                  depends on fluctuations in the financial market over which the
                  trader has no control and which may occur within the
                  withdrawal period (§ 312g Abs. 2 No. 8 BGB); and
                </li>
                <li>
                  contracts for the provision of betting and lottery services,
                  unless the consumer has made his contractual declaration by
                  telephone or the contract has been concluded away from
                  business premises (§ 312g Abs. 2 No. 12 BGB).
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">12 USE OF THE SERVICES</h3>
              <ol className="list-inside list-disc">
                <li>
                  Apart from the purchase of NFTs, the use of the Services is
                  free of charge.
                </li>
                <li>
                  To use the Services the User is required to have a device that
                  corresponds to the current state of technology. In order to be
                  able to use the Services, it is therefore necessary that the
                  User keeps the device with which the ORE Platform is accessed
                  up to date. Furthermore, in order to use the Services, the
                  User must have an internet connection that offers a sufficient
                  data transfer rate and there must be sufficient storage space
                  available on the User&#39;s device.
                </li>
                <li>
                  The User&#39;s right to use the respective current version of
                  the Services for its own purposes is limited to the provision
                  of the Services by OREMOB and is non-exclusive,
                  non-sublicensable and non-transferable.
                </li>
                <li>
                  The User shall &zwnj;
                  <ol className="list-inside list-disc pl-6">
                    <li>
                      not misuse the Services and may use the Services only in
                      accordance with the applicable law of the country in which
                      the Services are provided or used;
                    </li>
                    <li>
                      not take any action aimed at circumventing technical
                      protection measures of the Services and refrain from any
                      form of unauthorised use of the Services, in particular
                      attempts to overcome or circumvent the security mechanisms
                      of the Services or disable them in any other way, use
                      computer programs which enable the automatic reading of
                      data, as well as to use/implement and/or disseminate
                      viruses, worms, trojans, brute force attacks, spam or
                      links, programs or procedures which are suitable for
                      damaging OREMOB, the Services and/or other Users;
                    </li>
                    <li>
                      take all necessary and reasonable steps to prevent or
                      limit any damage caused by the use of the Services;
                    </li>
                    <li>
                      not use the Services for money laundering or other illegal
                      activities;
                    </li>
                    <li>
                      not engage in or promote illegal activities, in particular
                      fraudulent activities;
                    </li>
                    <li>
                      not use, employ or operate bots or other forms of
                      automation and/or multiple accounts when using the
                      Services;
                    </li>
                    <li>not modify, adapt or reverse engineer the Services.</li>
                  </ol>
                </li>
                <li>
                  Any breach of section 10.4 may result in immediate exclusion
                  from access and use of the Services, if applicable, burning of
                  the NFT (Buyer is obliged to cooperate), termination of the
                  Platform Agreement without notice and the initiation of civil
                  and criminal proceedings as well as the assertion of claims
                  for damages by OREMOB against the User.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">13 MODIFICATION, UPDATES, MAINTENANCE</h3>
              <ol className="list-inside list-disc">
                <li>
                  OREMOB reserves the right to modify or update the Services
                  from time to time by adding, deleting, or modifying features
                  to improve the User&#39;s experience or for other business
                  purposes and to discontinue any feature of the Services or any
                  portion thereof at any time during the term at OREMOB&#39;s
                  sole and reasonable discretion.
                </li>
                <li>
                  OREMOB reserves the right to temporarily restrict access to
                  the Services or the possibility of using them in whole or in
                  part if this is necessary due to capacity limits or the
                  security or integrity of the servers or in order to carry out
                  technical measures which serve the proper provision of the
                  services or improvement (e.g. for maintenance work).
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">
                14 TERMINATION OF THE ORE PLATFORM AGREEMENT; SUSPENSION OF THE
                SERVICES
              </h3>
              OREMOB may terminate the Platform Agreement at any time without
              notice in text form by suspending the Services.
            </li>
            <li>
              <h3 className="text-xl">15 LIABILITY</h3>
              <ol className="list-inside list-disc">
                <li>
                  The User acknowledges that legal regulations in the areas of
                  blockchain-based services, cryptocurrencies and crypto-assets
                  may change and that new regulations or guidelines may develop.
                  New or changing regulations or interpretation of existing laws
                  and regulations may adversely affect the qualification and/or
                  transfer and/or viability and/or value of the NFTs and the
                  Services in the future and OREMOB&#39;s future development
                  solutions. OREMOB shall not be liable for any losses that the
                  User may incur as a result of such possible developments and
                  the consequences, if any, thereof for OREMOB, the NFTs and/or
                  their benefit and value, if any.
                </li>
                <li>
                  In the event of any damage caused by slight negligence, OREMOB
                  shall only be liable in the event of a breach of a contractual
                  obligation, the fulfilment of which characterises the
                  respective agreement and on which the Buyer may rely and
                  limited to the foreseeable damage typical of the respective
                  agreement. Sentence 1 shall not apply to damages arising from
                  injury to life, body or health or in cases of mandatory
                  liability, in particular liability for cases in which a
                  procurement risk or a guarantee for damages has been assumed,
                  indemnification obligations, in the case of liability under
                  the Product Liability Act, liability under the DSGVO or
                  fraudulent concealment of a defect. &zwnj;
                </li>
                <li>
                  The liability of OREMOB in accordance with section 13.2
                  sentence 1 is limited to a total of EUR 10,000 for all damages
                  incurred.
                </li>
                <li>
                  Subject to liability for intent, liability for indirect
                  damage, such as loss of profit, is excluded.
                </li>
                <li>
                  OREMOB shall not be liable for any damages resulting from any
                  use or unauthorised modification of the NFT and/or the
                  Services by the User or any third party in breach of this
                  T&amp;C.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">
                16 RISK INFORMATION AND CONFIRMATION OF KNOWLEDGE
              </h3>
              <ol className="list-inside list-disc">
                <li>
                  The User is aware that the NFT has no fixed value and that the
                  price paid for the NFT is not indicative of the value of the
                  NFT and its future performance.
                </li>
                <li>
                  The User acknowledges that the existence and functioning of
                  the NFTs is dependent on the Blockchain, which OREMOB cannot
                  influence. For example, OREMOB cannot influence that a NFT is
                  properly created or secured or is retrievable and functional.
                  OREMOB does not owe the existence, security, interoperability
                  or maintenance of the NFTs. In particular, in the event of a
                  transaction of NFTs to an incorrect address, OREMOB is neither
                  able nor obliged to return the NFTs to the User. The User is
                  aware that the Blockchain can be changed without OREMOB being
                  able to intervene.
                </li>
                <li>
                  The User is aware that OREMOB cannot influence the Blockchain.
                  In the absence of control and influence, OREMOB is therefore
                  not responsible for the existence, security or maintenance of
                  the Blockchain. In the event of a faulty transaction, OREMOB
                  cannot make any corrections or influence the Blockchain.
                </li>
                <li>
                  The User confirms that he has sufficient knowledge of the
                  blockchain technology and knows how to secure his Wallet. He
                  will not transfer the NFT to other Users where he has
                  reasonable doubts that this is the case with the respective
                  users. He undertakes to deal with the associated risks before
                  the purchase and confirms his knowledge of this risk
                  information.
                </li>
                <li>
                  All materials on the ORE Platform or provided with the
                  Services are for informational purposes only. Neither OREMOB
                  nor any of the persons or entities involved in any way in
                  respect of the ORE Platform and/or the Services provide for
                  legal, fiscal, trading, economical and/or any other kind of
                  advice or recommendation that may be relied upon. This means
                  that the information from the ORE Platform cannot be used as a
                  basis of investment strategy and nothing in this information
                  can be ensured to contain no errors, mistakes,
                  malpresentations or failures. Users will therefore act at
                  their own risk in accessing or in any way relying on the
                  content of the ORE Platform or content provided with the
                  Services and the Users are therefore solely responsible for
                  any consequences thereof.
                </li>
                <li>
                  OREMOB does not owe Users any advice with regard to the
                  Services, in particular with regard to the purchase of NFTs.
                  Any purchase decision must be made by the User on his own
                  responsibility. OREMOB cannot and will not provide the User
                  with any binding information on future price developments,
                  tradability, market developments or similar economic
                  forecasts. All information on usage options and NFTs, in
                  particular information on price developments, are general
                  comparative values which do not relate to the price
                  development of the specific NFT. They are non-binding
                  indicators. OREMOB gives no assurance or warranty with regard
                  to forward-looking statements. The User is aware that the
                  value of an NFT is inherently subjective and that there is no
                  inherent or intrinsic value. The value of the NFT follows in
                  particular from the value attributed by the market to the
                  rights to use a medium granted by the licence agreement to the
                  holder of a NFT.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">17 ANTI-MONEY LAUNDERING &zwnj;</h3>
              <ol className="list-inside list-disc">
                <li>
                  The User warrants,
                  <ol className="list-inside list-disc pl-6">
                    <li>
                      that the NFT will not be used in any manner for any
                      illegal or unethical purpose, including acts related to
                      money laundering, terrorist financing or other acts that
                      violate applicable law;
                    </li>
                    <li>
                      that the User will not use any proceeds of criminal or
                      illegal acts; and
                    </li>
                    <li>
                      that no transactions with the NFT will be used to
                      facilitate or engage in criminal or illegal acts,
                      including acts related to money laundering, terrorist
                      financing or other acts that violate applicable law.
                    </li>
                  </ol>
                </li>
                <li>
                  The User represents and warrants that, as of the date of
                  entering this T&amp;C, no criminal or regulatory investigation
                  is pending against the User, any affiliate of the User, any
                  officer or shareholder of the User in connection with the
                  User&#39;s business activities.
                </li>
                <li>
                  The User warrants that, upon conclusion of this Platform
                  Agreement,
                  <ol className="list-inside list-disc pl-6">
                    <li>
                      he is not included on a sanctions list of the United
                      Nations, the European Union or the Federal Republic of
                      Germany;
                    </li>
                    <li>
                      he is not acting indirectly (e.g., as a deputy or
                      messenger) for a person who is on one of the sanctions
                      lists referred to in section a).
                    </li>
                  </ol>
                </li>
                <li>
                  Should any of the events referred to in this section 15 occur
                  after the conclusion of the Purchase Agreement, the User
                  undertakes to notify the OREMOB immediately and to immediately
                  suspend all transactions in connection with the NFT.
                </li>
                <li>
                  The User confirms that he is acting exclusively in his own
                  economic interest.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">18 INDEMNIFICATION</h3>
              The User undertakes to indemnify OREMOB in full against any claims
              by third parties based on an infringement of rights attributable
              to the User, unless the User proves that he is not responsible for
              the breach of duty causing the damage.
            </li>
            <li>
              <h3 className="text-xl">19 INTELLECTUAL PROPERTY RIGHTS</h3>
              <ol className="list-inside list-disc">
                <li>
                  All text, graphics, user interfaces, visual interfaces,
                  photographs, logos, artwork and computer code provided on the
                  ORE Platform, including but not limited to the design,
                  structure, selection, coordination, expression and arrangement
                  of the content contained on the ORE Platform are intellectual
                  property of OREMOB, and are protected by copyright, patent and
                  trademark laws, and various other intellectual property
                  rights.
                </li>
                <li>
                  Except as expressly provided in these T&amp;C, no part of the
                  ORE Platform and no content indicated therein may be copied,
                  reproduced, republished, uploaded, posted, publicly displayed,
                  encoded, translated, transmitted or distributed in any way to
                  any other computer, server, website or other medium for
                  publication or distribution for any commercial purpose,
                  without OREMOB&#39;s express prior written consent.
                </li>
                <li>
                  The User acknowledges that all enhancements provided by OREMOB
                  are the sole property of OREMOB or third parties, even if they
                  are the result of functional requests or bug reports from
                  Users, and that the User has no rights thereto.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">20 LINKS TO OTHER WEBSITES</h3>
              The User uses all links provided on the ORE Platform to visit
              third-party websites, including websites of Third-Party Services,
              at his own risk. When the User leaves the ORE Platform, the
              information he views is not provided by OREMOB. These T&amp;C do
              not govern the use of third-party websites, including websites of
              Third-Party Services. OREMOB does not monitor or have any control
              over and makes no claim or representation regarding these
              websites. To the extent such links are provided on the ORE
              Platform, they are provided only as a convenience, and, unless
              otherwise stated, a link to another website does not imply
              OREMOB&#39;s endorsement, adoption or sponsorship of, or
              affiliation with, such websites.
            </li>
            <li>
              <h3 className="text-xl">21 PRIVACY POLICY</h3>
              OREMOB&#39;s privacy policy can be found at
              https://oremob.io/legal-notice; the privacy policy is not the
              subject of this T&amp;C.
            </li>
            <li>
              <h3 className="text-xl">22 AMENDMENTS TO THIS T&amp;C</h3>
              OREMOB reserves the right to change these T&amp;C at any time and
              without giving reasons, unless this is unacceptable for the User.
              OREMOB will inform the User of changes of the T&amp;C in good time
              via the ORE Platform. If the User does not object to the validity
              of the new terms of use within four weeks after notification, the
              amended terms of use shall be deemed accepted by the User. OREMOB
              will inform the User in the notification of his right to object
              and the significance of the objection period. In the event of an
              objection by the User, the authorisation to use the Services shall
              end and OREMOB shall be entitled to delete the data of the User
              concerned. Changes to the main contractual Service obligations or
              charges for these main Service obligations require the express
              consent of the User to become effective.
            </li>
            <li>
              <h3 className="text-xl">23 DISPUTE RESOLUTION</h3>
              <ol className="list-inside list-disc">
                <li>
                  In accordance with Art. 14 (1) of Regulation (EU) No. 524/2013
                  on Online Dispute Resolution (ODR Regulation), OREMOB is
                  legally obliged to refer the Buyer to the European Online
                  Dispute Resolution platform (ODR platform) of the European
                  Commission. The User can reach this at{" "}
                  <a
                    href="http://ec.eoropa.eu/consumers/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://ec.eoropa.eu/consumers/
                  </a>
                  <u>odr/.</u>
                </li>
                <li>
                  OREMOB does not participate in dispute resolution proceedings
                  before a consumer arbitration board and is not obliged to do
                  so.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">24 CHOICE OF LAW AND JURISDICTION</h3>
              <ol>
                <li>
                  This T&amp;C and its interpretation and any non-contractual
                  obligations in connection with it are subject to German
                  substantive law. As far as applicable, the UN Convention on
                  Contracts for the International Sale of Goods (CISG) shall not
                  apply.
                </li>
                <li>
                  English language terms used in this T&amp;C describe German
                  legal concepts only and shall not be interpreted by reference
                  to any meaning attributed to them in any jurisdiction other
                  than Germany. Where a German term has been inserted in
                  brackets and/or italics it alone (and not the English term to
                  which relates) shall be authoritative for the purpose of the
                  interpretation of the relevant term whenever it is used in
                  this T&amp;C.
                </li>
                <li>
                  Exclusive place of jurisdiction for all disputes regarding
                  rights and duties under this T&amp;C, including its validity
                  shall be Berlin.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="text-xl">25 MISCELLANEOUS</h3>
              <ol className="list-inside list-disc">
                <li>
                  Should one or more provisions of this T&amp;C be or become
                  invalid or unenforceable in whole or in part, this shall not
                  affect the validity and enforceability of the remaining
                  provisions of this T&amp;C. In place of any Standard Terms of
                  Business (<i>Allgemeine Geschäftsbedingungen</i>) which are
                  invalid or not incorporated in the T&amp;C the statutory
                  provisions shall apply (Section 306 (2) BGB). In all other
                  cases, the parties shall replace the void or invalid provision
                  with a valid provision which reflects as closely as possible
                  the original economic purpose of the void or invalid
                  provision, unless a supplementary interpretation (
                  <i>ergänzende Vertragsauslegung</i>) of the T&amp;C takes
                  precedence or is possible.
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <h2 className="m-0 pl-0 font-gravity-compressed text-[60px] uppercase lg:text-[80px]">
          Disclaimer
        </h2>

        <div className="space-y-3">
          <ul className="list-none">
            <li>
              You understand and agree that you are solely responsible for
              determining the nature, potential value, suitability and
              appropriateness when buying NFTs for yourself.
            </li>
          </ul>
        </div>

        <h2
          id="privacy-policy"
          className="m-0 pl-0 font-gravity-compressed text-[60px] uppercase lg:text-[80px]"
        >
          Privacy Policy
        </h2>

        <div>
          <p className="font-gravity-wide text-xl">Cookies </p>
          <p>
            Our website uses cookies. Cookies are small text files which are
            saved in a user’s internet browser or by the user’s internet browser
            on their computer system. When a user calls up a website, a cookie
            may be saved on the user’s operating system. This cookie contains a
            characteristic character string which allows the browser to be
            clearly identified when the website is called up again.
          </p>
          <p>
            We use these cookies to make our offering more user-friendly,
            effective and secure. Cookies also allow our systems to recognise
            your browser after a page change and to offer you services. Some
            functions of our website cannot be offered without the use of
            cookies. These services require the browser to be recognised again
            after a page change.
          </p>
          <p>
            Cookies will be stored on your computer. You therefore have full
            control over the use of cookies. By choosing corresponding technical
            settings in your internet browser, you can be notified before the
            setting of cookies and you can decide whether to accept this setting
            in each individual case as well as prevent the storage of cookies
            and transmission of the data they contain. Cookies which have
            already been saved may be deleted at any time. We would, however,
            like to point out that this may prevent you from making full use of
            all the functions of this website.
          </p>
          <p>
            Using the links below, you can find out how to manage cookies (or
            deactivate them, among other things) in major browsers:
          </p>
          <p>
            Chrome Browser:{" "}
            <a
              href="https://support.google.com/accounts/answer/61416?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/accounts/answer/61416?hl=en
            </a>
          </p>
          <p>
            Internet Explorer:{" "}
            <a
              href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </p>
          <p>
            Mozilla Firefox:{" "}
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              target="_blank"
              rel="noreferrer"
            >
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
          </p>
          <p>
            Safari:{" "}
            <a
              href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
          </p>
          <br />
          <br />
          <p className="font-gravity-wide text-xl">Streitschlichtung</p>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <br />
          <br />
          <p className="font-gravity-wide text-xl">Haftung für Inhalte</p>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">Haftung für Links</p>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">Urheberrecht</p>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            1. Datenschutz auf einen Blick
          </p>
          <p>Allgemeine Hinweise</p>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <p>Datenerfassung auf unserer Website</p>
          <p>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </p>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Wie erfassen wir Ihre Daten?
          </p>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere
            Website betreten.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Wofür nutzen wir Ihre Daten?
          </p>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </p>
          <p>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
            unter „Recht auf Einschränkung der Verarbeitung“.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Analyse-Tools und Tools von Drittanbietern
          </p>
          <p>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung.
          </p>
          <p>
            Sie können dieser Analyse widersprechen. Über die
            Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            2. Allgemeine Hinweise und Pflichtinformationen
          </p>
          <p>
            <em>Datenschutz</em>
          </p>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Hinweis zur verantwortlichen Stelle
          </p>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>Ole Paland, Südwestkorso 68, 12161 Berlin</p>
          <p>
            Telefon: 004915737189406
            <br />
            E-Mail: oretimes3@gmail.com
          </p>

          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </p>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <p>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </p>
          <p>
            Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
            oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
            die sich aus Ihrer besonderen Situation ergeben, gegen die
            Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
            dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
            entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch
            einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht
            mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            (Widerspruch nach Art. 21 Abs. 1 DSGVO).
          </p>
          <p>
            Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
            widersprechen, werden Ihre personenbezogenen Daten anschließend
            nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach
            Art. 21 Abs. 2 DSGVO).
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </p>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Recht auf Datenübertragbarkeit
          </p>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Auskunft, Sperrung, Löschung und Berichtigung
          </p>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Recht auf Einschränkung der Verarbeitung
          </p>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:
          </p>
          <p>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen.
            <br />
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah / geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen.
            <br />
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
            <br />
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abwägung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
            Ihrer personenbezogenen Daten zu verlangen.
            <br />
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Widerspruch gegen Werbe-E-Mails
          </p>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            3. Datenerfassung auf unserer Website
          </p>
          <p>Server-Log-Dateien</p>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>
          <p>
            Browsertyp und Browserversion
            <br />
            verwendetes Betriebssystem
            <br />
            Referrer URL
            <br />
            Hostname des zugreifenden Rechners
            <br />
            Uhrzeit der Serveranfrage
            <br />
            IP-Adresse
            <br />
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </p>
          <p>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der technisch fehlerfreien Darstellung und der Optimierung seiner
            Website – hierzu müssen die Server-Log-Files erfasst werden.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">Kontaktformular</p>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">
            Verarbeiten von Daten (Kunden- und Vertragsdaten)
          </p>
          <p>
            Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
            soweit sie für die Begründung, inhaltliche Ausgestaltung oder
            Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
            Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
            Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über
            die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen.
          </p>
          <p>
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
            Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
            Aufbewahrungsfristen bleiben unberührt.
          </p>
          <p>
            Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und
            Warenversand
          </p>
          <p>
            Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
            im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der
            Lieferung der Ware betrauten Unternehmen oder das mit der
            Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
            Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
            Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
            Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
            Werbung, erfolgt nicht.
          </p>
          <p>
            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
            der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet.
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">4. Soziale Medien</p>
          <p>Facebook-Plugins (Like &amp; Share-Button)</p>
          <p>
            Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
            Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025,
            USA, integriert. Die Facebook-Plugins erkennen Sie an dem
            Facebook-Logo oder dem &quot;Like-Button&quot; (&quot;Gefällt
            mir&quot;) auf unserer Seite. Eine Übersicht über die
            Facebook-Plugins finden Sie hier:{" "}
            <a
              href="https://developers.facebook.com/docs/plugins/"
              target="_blank"
              rel="noreferrer"
            >
              https://developers.facebook.com/docs/plugins/.
            </a>
          </p>
          <p>
            Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
            Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
            &quot;Like-Button&quot; anklicken während Sie in Ihrem
            Facebook-Account eingeloggt sind, können Sie die Inhalte unserer
            Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook
            den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen
            darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
            Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
            erhalten. Weitere Informationen hierzu finden Sie in der
            Datenschutzerklärung von Facebook unter:{" "}
            <a
              href="https://www.facebook.com/privacy/explanation."
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/privacy/explanation.
            </a>
          </p>
          <p>
            Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
            Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
            Ihrem Facebook-Benutzerkonto aus.
          </p>
          <p>
            Die Verwendung der Facebook-Plugins erfolgt auf Grundlage von Art. 6
            Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer möglichst umfangreichen Sichtbarkeit in den
            Sozialen Medien.
          </p>
          <p>&zwj;</p>
          <p>
            <a
              href="https://www.youtube.com/t/privacy_at_youtube"
              target="_blank"
              rel="noreferrer"
            >
              https://www.youtube.com/t/privacy_at_youtube
            </a>
            .
          </p>

          <br />
          <br />
          <p className="font-gravity-wide text-xl">Google Web Fonts</p>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Beim
            Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          </p>
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
            Die Nutzung von Google Web Fonts erfolgt im Interesse einer
            einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
            Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
            lit. f DSGVO dar.
          </p>
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
            <a
              href="https://developers.google.com/fonts/faq"
              target="_blank"
              rel="noreferrer"
            >
              https://developers.google.com/fonts/faq
            </a>{" "}
            und in der Datenschutzerklärung von Google:{" "}
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.google.com/policies/privacy/.
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default LegalPage
